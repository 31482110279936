<template>
	<section class="talleres-main px-3 ">
		<navbar-admin icon="icon-dolar" />
        <tabs :tabs="tabs" />
		<router-view/>
	</section>
</template>

<script>
const URL_FULL_SMART = process.env.VUE_APP_URL_FULL_SMART
import { mapActions } from 'vuex'
export default {
	data(){
		return {
			tabs: [
				{
					titulo:'Lista de ventas',
					callback: () => {
						if(URL_FULL_SMART) window.location.href = URL_FULL_SMART + '/ventas/listado'
					} 
				},
				{
					titulo:'Aprobación de precios',
					callback: () => {
						if(URL_FULL_SMART) window.location.href = URL_FULL_SMART + '/ventas/aprobacion-precios/mis-solicitudes'
					} 
				},
				{
					titulo:'Productos vendidos',
					callback: () => {
						if(URL_FULL_SMART) window.location.href = URL_FULL_SMART + '/ventas/analisis'
					} 
				},
				{
					titulo:'Informe de ventas',
					callback: () => {
						if(URL_FULL_SMART) window.location.href = URL_FULL_SMART + '/ventas/informe'
					} 
				},
				{
					titulo:'Presencia',
					callback: () => {
						if(URL_FULL_SMART) window.location.href = URL_FULL_SMART + '/ventas/presencia'
					} 
				},
				{
					titulo:'Presencia de equipos',
					ruta:"ventas.presencia-equipos",
				},
			],
		}
	},
	created(){
		this.getBreadcumbs(['ventas.main'])
	},
	methods: {
		...mapActions({
			getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs'
		})
	}
}
</script>